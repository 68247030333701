<!-- Base Finder Editable en linea -->

<template>
  <div v-if="schema" class="base_Fedit">
    <base_F :schema="schema" :Entorno="Entorno" @onEvent="event_capture">
      <!-- Slot Campos x fila -->
      <template v-slot:fields_grid="{ Sitem, Sheader }">       
        <template v-if="schema.ctrls[Sheader.value].editable">
          <!-- El campo es del tipo indicado en 'type' -->
          <template v-if="schema.ctrls[Sheader.value].comp">
            <!-- Control tipo select -->
            <template v-if="schema.ctrls[Sheader.value].comp.type === 'select'">
              <v-select
                v-bind="$select"
                v-model="Sitem.item[Sheader.value]"
                :items="get_selectItems(Sheader.value)"
                :label="schema.ctrls[Sheader.value].label"
                item-value="id"
                item-text="name"
                :disabled="!is_edition(Sitem)"
              >
              </v-select>
            </template>

            <!-- Control tipo checkbox -->
            <template
              v-else-if="schema.ctrls[Sheader.value].comp.type === 'check'"
            >
              <v-checkbox
                v-bind="$checkbox"
                v-model="Sitem.item[Sheader.value]"
                :disabled="!is_edition(Sitem)"
              >
              </v-checkbox>
            </template>

            <!-- Control tipo icono -->
            <template
              v-else-if="schema.ctrls[Sheader.value].comp.type === 'icono'"
            >
              <v-icon icon>
                {{ `mdi-${Sitem.item[Sheader.value]}` }}
              </v-icon>
            </template>
            <!-- :schema="schema.ctrls[Sheader.value]" -->
            <!-- Control tipo componente fecha -->
            <template
              v-else-if="schema.ctrls[Sheader.value].comp.type === 'fecha'"
            >
              <compfecha
                style="flex: 0 0 25%"
                :data="Sitem.item"
                :data_fieldName="Sheader.value"
                :schema="schema.ctrls[Sheader.value]"
                :edicion="is_edition(Sitem)"
              >
              </compfecha>
            </template>

            <!-- Control tipo componente pick -->
            <template v-else-if="schema.ctrls[Sheader.value].comp.type === 'pick'">
                <ctrlfinder
                    :data="Sitem.item"
                    :data_fieldName="Sheader.value"
                    data_aux= 'salamanca'
                    :schema="schema.ctrls[Sheader.value]"
                    :edicion="is_edition(Sitem)">
                </ctrlfinder>
            </template>
          </template>

          <!-- Campo por defecto como 'input' -->
          <template v-else>
            <v-text-field
              v-bind="$input"
              v-model="Sitem.item[Sheader.value]"
              :label="schema.ctrls[Sheader.value].label"
              :disabled="!is_edition(Sitem)"
            >
            </v-text-field>
          </template>
        </template>

        <template v-else>
          {{ Sitem.item[Sheader.value] }}
        </template>
      </template>

      <!-- Slot Btra de acciones x fila -->
      <template v-slot:acciones_grid="{ Sitem }">
        <template v-if="is_edition(Sitem)">
          <v-btn
            v-bind="$cfg.btra_cfg.mrow"
            title="Cancelar"
            @click="event_capture({ accion: 5, row: Sitem })"
          >
            <v-icon v-bind="$cfg.btra_cfg.mrow.icons">mdi-close-circle</v-icon>
          </v-btn>

          <v-btn
            v-bind="$cfg.btra_cfg.mrow"
            title="Guardar"
            @click="event_capture({ accion: 4, row: Sitem })"
          >
            <v-icon v-bind="$cfg.btra_cfg.mrow.icons">mdi-content-save</v-icon>
          </v-btn>
        </template>

        <template v-else>
          <v-btn
            v-if="schema.btra.mto[2] === '1'"
            v-bind="$cfg.btra_cfg.mrow"
            title="Editar"
            @click="event_capture({ accion: 2, row: Sitem })"
          >
            <v-icon v-bind="$cfg.btra_cfg.mrow.icons">mdi-pencil</v-icon>
          </v-btn>

          <v-btn
            v-if="schema.btra.mto[3] === '1'"
            v-bind="$cfg.btra_cfg.mrow"
            title="Eliminar"
            @click="event_capture({ accion: 3, row: Sitem })"
          >
            <v-icon v-bind="$cfg.btra_cfg.mrow.icons">mdi-delete</v-icon>
          </v-btn>
        </template>
      </template>
    </base_F>
  </div>
</template>



<script>
    import { mixinCtrls } from "@/mixins/mixinCtrls";
    import plugs from "@/common/general_plugs";
    const base_F = () => plugs.groute("base_F.vue", "base");
    const compfecha = () => plugs.groute("compfecha.vue", "comp");
    const ctrlfinder = () => plugs.groute("ctrlfinder.vue", "comp");

    export default {
      mixins: [mixinCtrls],
      components: { base_F, compfecha, ctrlfinder },
      props: {
          source: { type: Object, default: () => {} },
          schema: { type: [Array, Object], default: undefined },
          Entorno: { type: Object, default: () => undefined }
      },

      created() {
          this.get_entorno();
      },

      methods: 
      {
          // gestor de eventos
          async event_capture(evt) {
            console.log("*** onEvent base_Fedit. Accion: ", evt, " ***");

            if (this.$isFunction(this[evt.accion])) { await this[evt.accion](evt); return; }
            this.$emit("onEvent", evt);
          },

          // entorno particular
          get_entorno() {},

          // compruebo si la fila recibida está en edición
          is_edition(row) 
          {
            if (row.index === this.Entorno.grid.row.row_inEdition) return true;
            return false;
          },

          // devuelvo array de elementos de un select
          get_selectItems(name) 
          {
            if (!this.source[name]) return;
            return this.source[name];
          },

      }
    };
</script>
